import { Fragment, useContext, useRef, useState } from "react";
import "./ContactSidebar.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { webContext } from "../../../webContext/WebContext";
import { IoMdClose } from "react-icons/io";
import TextError from "../../textError/TextError";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { TfiAngleDown } from "react-icons/tfi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countries } from "../../../utils/country";
import axios from "axios";
import { baseUrl, contact } from "../../../utils/apiData";
import { IoSearchSharp } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";

const ContactSidebar = () => {
  const recaptchaRef = useRef();
  const [captcha, setCaptcha] = useState(null);

  const { contactStatus, openContact, closeContact } = useContext(webContext);
  const [selectedPill, setSelectedPill] = useState([]);
  const [ddStatus, setDdStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  const pillClickHandler = (item) => {
    setSelectedPill((prev) => {
      if (prev?.includes(item)) {
        return prev.filter((data) => data !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [search, setSearch] = useState("");
  const [success, setSuccess] = useState(false);

  const resetAll = () => {
    setCountry("");
    setPhone("");
    setSelectedPill([]);
  };

  const pillsData = [
    "UI/UX Design",
    "Web Development",
    "App Development",
    "Branding",
    "SEO",
  ];

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is required!"),
  });

  const pageClick = () => {
    setDdStatus(false);
  };

  // Contact API

  const contactApi = async (body) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${contact}`, { ...body });
      if (res?.data?.success) {
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const onSubmit = (values, { resetForm }) => {
    contactApi({ ...values, help: selectedPill, phone, country });
    resetForm();

    resetAll();
  };

  const closeMsg = () => {
    setSuccess(false);
  };

  return (
    <Offcanvas
      className="contact_sidebar"
      show={contactStatus}
      placement="end"
      onHide={() => {
        closeContact();
        resetAll();
      }}
    >
      <Offcanvas.Header onClick={pageClick}>
        <Offcanvas.Title>
          <h3>Get in Touch</h3>
          <button type="button" onClick={closeContact}>
            <IoMdClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body onClick={pageClick}>
        {success ? (
          <div className="success_screen">
            <button type="button" className="msg_close" onClick={closeMsg}>
              <IoMdClose />
            </button>
            <h3>
              Thanks for reaching out! <br />
              We'll reply soon.
            </h3>
          </div>
        ) : (
          <Fragment>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="bi_sec">
                  <div className="field_wrap">
                    <Field
                      type="text"
                      placeholder="Enter Name"
                      id="name"
                      name="name"
                    />
                    <ErrorMessage name="name" component={TextError} />
                  </div>

                  <div className="field_wrap">
                    <Field
                      type="email"
                      placeholder="Enter Mail"
                      id="email"
                      name="email"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </div>
                </div>

                <div className="bi_sec mt">
                  <div className="field_wrap">
                    <PhoneInput
                      country={"in"}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      className="wow fadeInUp"
                    />
                  </div>
                  <div className="field_wrap">
                    <div
                      className={`select_tag ${ddStatus ? "open" : "close"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDdStatus((prev) => !prev);
                      }}
                    >
                      <p>{country ? country : "- Select Country -"}</p>
                      <span
                        style={{
                          transform: `rotate(${ddStatus ? 180 : 0}deg)`,
                        }}
                      >
                        <TfiAngleDown />
                      </span>
                      <div
                        className="option_wrap"
                        style={{ transform: `scaleY(${ddStatus ? 1 : 0})` }}
                      >
                        <div
                          className="search"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <IoSearchSharp />
                          <input
                            type="text"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                        {countries.map((data) => {
                          return (
                            data?.name
                              ?.toLocaleLowerCase()
                              .includes(search?.toLocaleLowerCase()) && (
                              <button
                                type="button"
                                className={`${
                                  country === data.name ? "active" : ""
                                }`}
                                key={data?.code}
                                onClick={() => setCountry(data.name)}
                              >
                                {data?.name}
                              </button>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field_wrap">
                  <Field
                    as="textarea"
                    type="text"
                    placeholder="Enter Message"
                    id="message"
                    name="message"
                  />
                  <ErrorMessage name="message" component={TextError} />
                </div>

                <div className="pills_sec">
                  <p>How we can help?</p>
                  <div className="pills_wrap">
                    {pillsData.map((data, k) => {
                      return (
                        <button
                          type="button"
                          className={`pills_block ${
                            selectedPill?.includes(data) ? "active" : ""
                          }`}
                          onClick={() => pillClickHandler(data)}
                          key={k}
                        >
                          <p>{data}</p>
                        </button>
                      );
                    })}
                  </div>
                </div>

                <div className="captcha_wrap">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfHkBoqAAAAAKcMdv00b5hPI0GoFHB7ACb_xgSC"
                    onChange={(value) => setCaptcha(value)}
                  />
                </div>
                {loader ? (
                  <PrimaryBtn type="button" title="Loading..." />
                ) : (
                  <PrimaryBtn
                    type="submit"
                    title="Submit"
                    disabled={selectedPill?.length === 0 || !captcha}
                  />
                )}
              </Form>
            </Formik>
            <div className="bottom_text">
              <p>
                For support mail us at
                <a
                  href="mailto:support@supagrow.in"
                  target="_blank"
                  className="ms-1"
                >
                  <span>support@supagrow.in</span>
                </a>
              </p>
              <p style={{ marginTop: "16px" }}>
                <a href="tel:+919106574462" className="call">
                  <MdCall /> +91 9106574462
                </a>
              </p>
            </div>
          </Fragment>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ContactSidebar;
